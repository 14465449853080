//
import VTableSurvey from '@/components/TableSurvey'
import PProviderService from '@/services/api/Provider/PProvider'
// import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-survey',
  components: {
    VTableSurvey,
  },
  data() {
    return {
      
      busy: false,
      //Se agregan estos dos boolean para verificar que se muestre solo la encuesta de cierto tipo
      isFaena:false,
      isOutFaena:false,
      dialogs: {
        survey: false,
        reset: false,
        confirm: false,
        save: false,
      },
      athLogin: {},
      sections : {
        one: false,
      },
      mcSurvey: {},
      mcSurveyOutFaena:{},
      display: {},
      answers: {},
    }
  },
  methods: {
    checkTheBox(){
      console.log('value> ',this.esEnLaFaena)
    },
    /**
     * 
     */
    getAnswers() {
      let answersInit = { questions: [] }
      return Object.values(this.answers).reduce((answers, item) => {
        answers.questions.push(item)
        return answers
      }, answersInit)
    },

    /**
     * 
     */
    handleFormReset() {
      PProviderService.getSurvey(this.$provider.id).then(res => {
        if(res[0].faena){
          this.mcSurvey = res[1].data
          this.mcSurveyOutFaena= res[0].data
        }else{
          this.mcSurvey = res[0].data
          this.mcSurveyOutFaena= res[1].data
        }
      })
    },

    /**
     * 
     */
    handleFormSubmit() {
      this.busy = true
      this.dialogs.confirm = false
      if(this.isFaena){
      PProviderService.answerSurvey(this.$provider.id, this.getAnswers())
        .then(res => {
          this.busy = false
          this.$toast.success('Encuesta enviada exitosamente.')
          this.$router.push('/perfil/proveedor')
          console.log({ res })
        })
        .catch(error => {
          console.log({ error })
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.busy = false
        })
      }
      if(this.isOutFaena){
        PProviderService.answerSurveyFaena(this.$provider.id, this.getAnswers())
        .then(res => {
          this.busy = false
          this.$toast.success('Encuesta enviada exitosamente.')
          this.$router.push('/perfil/proveedor')
          console.log({ res })
        })
        .catch(error => {
          console.log({ error })
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.busy = false
        })
      }
    },

    /**
     * 
     */
    handleFormSave() {
      this.busy = true
      this.dialogs.save = false
      PProviderService.saveSurvey(this.$provider.id, this.getAnswers())
        .then(() => {
          this.busy = false
          this.$toast.success('Encuesta guardada exitosamente.')
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.busy = false
        })
        PProviderService.saveSurveyFaena(this.$provider.id, this.getAnswers())
        .then(() => {
          this.busy = false
          this.$toast.success('Encuesta Faena guardada exitosamente.')
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
        .finally(() => {
          this.busy = false
        })
    },

    /**
     * 
     */
    handleSurveyItemClick(event) {
      this.answers[event.question] = {
        question: event.question,
        yesOrNo : event.answer,
        comment : event.comment ? String(event.comment) : "",
      }
    },

    /**
     * 
     */
    handleSurveyItemComment(event) {
      console.log({ event })
    },
  },
  mounted() {
    this.handleFormReset()
  },
  props: {

    /**
     * 
     */
    surveyItems: {
      type: Array,
      default: () => [
        {
          text: "¿Cómo Empresa Proveedora y Contratista, cuenta con el detalle de los alcances, políticas, requerimientos y sistemas de verificación del cumplimiento de seguridad que debe cumplir en el trabajo industrial minero?",
        },
        {
          text: "¿Cómo Empresa Proveedora y Contratista, aplica el Modelo de Gestión de Riesgo de Procesos (GRP)?",
        },
        {
          text: "¿Considera para la GRP los alcances, políticas, requerimientos y sistemas de verificación del cumplimiento de seguridad que debe cumplir en el trabajo industrial minero?",
        },
        {
          text: "¿Cuenta con los requisitos HSEC (Salud – Seguridad – Comunidad – Medio Ambiente) y gestión de protocolos de seguridad y medio ambiente exigidos?",
        },
        {
          text: "¿Cumple con el Reglamento Proveedores – Contratistas para el control de acceso a faenas?",
        },
        {
          text: "¿Posee una Hoja de Ruta para los nuevos contratos adjudicados?",
        },
        {
          text: "¿Realiza periódicamente a su equipo de trabajo en faenas programas preventivos de Alcohol y Drogas?",
        },
      ],
    }
  },
}